import * as CC from '@chakra-ui/react'
import Link from 'next/link'
import React from 'react'
import { CardImage } from 'src/features/account/components/favorites'
import { Asset, Board, BreweryRoute, Favorites } from 'src/types/schema/graphql'
import { getBoardAssets } from '../../helpers/getBoardAssets'
import LargeGridSquare from './LargeGridSquare'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'

interface IBoardsCard {
    board: Board
    boardTitle: string
    routesLength: number
    favorites: Favorites
    boardLink: string
    route?: BreweryRoute
}

const BoardPreviewCard: React.FC<IBoardsCard> = ({
    board,
    boardTitle,
    routesLength,
    favorites,
    boardLink,
    route,
}) => {
    const [allAssets, setAllAssets] = React.useState<Asset[]>([])
    const setUpdateBoardModalOpen = useSavedBoardsStore((state) => state.setUpdateBoardModalOpen)
    const setUpdateBoardData = useSavedBoardsStore((state) => state.setUpdateBoardData)

    React.useEffect(() => {
        ;(async () => {
            const { productAssets, locationAssets, brandAssets } = await getBoardAssets(
                board?.favorites,
                board?.title
            )
            const displayAssets = [
                locationAssets[0],
                productAssets[0],
                productAssets[1],
                productAssets[2],
                locationAssets[1],
                locationAssets[2],
                brandAssets[0],
                brandAssets[1],
                brandAssets[2],
            ]
            const filtered = displayAssets.filter((item) => item !== undefined)
            setAllAssets(filtered)
        })()
    }, [])
    const startIndex = route ? 0 : 1
    const endIndex = route ? 2 : 3
    const totalLength = allAssets?.slice(startIndex, endIndex)?.length
    const totalBrewsCount = favorites?.favoriteProducts?.filter((item) => item?.isActive)?.length
    const totalBrandLocationCount =
        favorites?.favoriteLocations?.filter((item) => item?.isActive)?.length +
        favorites?.favoriteBrands?.filter((item) => item?.isActive)?.length

    return (
        <CC.Box>
            <Link href={boardLink}>
                <a>
                    <CC.Grid
                        h={'auto'}
                        templateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
                        gap={3}
                    >
                        <CC.GridItem
                            rowSpan={2}
                            colSpan={2}
                            bg='gray.100'
                            rounded={'xl'}
                            minWidth='9rem'
                            minHeight={'15.25rem'}
                        >
                            <LargeGridSquare route={route} assets={allAssets} />
                        </CC.GridItem>
                        {allAssets?.slice(startIndex, endIndex)?.map((item) => (
                            <CC.GridItem
                                key={item?.path}
                                colSpan={1}
                                bg='gray.100'
                                rounded={'xl'}
                                minWidth={'7.25rem'}
                                aspectRatio={'1/1'}
                            >
                                <CardImage
                                    customClasses='overflow-hidden rounded-xl object-contain'
                                    asset={item}
                                    alt={item?.alt}
                                />
                            </CC.GridItem>
                        ))}
                        {totalLength < 2 && (
                            <CC.GridItem
                                colSpan={1}
                                bg='gray.100'
                                rounded={'xl'}
                                minWidth={'7.25rem'}
                                aspectRatio={'1/1'}
                            ></CC.GridItem>
                        )}
                        {totalLength === 0 && (
                            <CC.GridItem
                                colSpan={1}
                                bg='gray.100'
                                rounded={'xl'}
                                minWidth={'7.25rem'}
                                aspectRatio={'1/1'}
                            ></CC.GridItem>
                        )}
                    </CC.Grid>
                    <CC.VStack spacing={'sm'} mt={4} align='stretch'>
                        <CC.HStack justifyContent='space-between'>
                            <CC.Text fontSize='md' as='b' color='dark'>
                                {boardTitle}
                            </CC.Text>
                            {/* @TODO: Save for when edit boards is ready */}
                            {/* <CC.Box>
                                <CC.Icon boxSize={'1.5rem'} as={HeroIcons.DotsHorizontalIcon} />
                            </CC.Box> */}
                        </CC.HStack>
                        <CC.HStack spacing={2}>
                            <CC.Text fontSize='sm' color='dark'>
                                {routesLength} Routes
                            </CC.Text>
                            <span>·</span>
                            <CC.Text fontSize='sm' color='dark'>
                                {totalBrandLocationCount}{' '}
                                {`Brewer${totalBrandLocationCount === 1 ? 'y' : 'ies'}`}
                            </CC.Text>
                            <span>·</span>
                            <CC.Text fontSize='sm' color='dark'>
                                {totalBrewsCount} Brews
                            </CC.Text>
                        </CC.HStack>
                    </CC.VStack>
                </a>
            </Link>
            {board?.description && (
                <CC.VStack
                    className='mt-2 bg-gray-50'
                    resize='none'
                    fontSize={'sm'}
                    alignItems='flex-start'
                    rounded='xl'
                    p='4'
                    w='full'
                >
                    <div className='w-full'>{board?.description}</div>
                </CC.VStack>
            )}
        </CC.Box>
    )
}

export default React.memo(BoardPreviewCard)
